// Add .page-island to avoid .ts import order

.page-arena-1 {
  --arena-color-1: #75c612;
}

.page-arena-2 {
  --arena-color-1: #009026;
}

.page-arena-3 {
  --arena-color-1: #ff4c00;
}

.page-arena-4 {
  --arena-color-1: #FF8D00;
}

.page-arena-5 {
  --arena-color-1: #b87d3f;
}

.page-arena-6 {
  --arena-color-1: #0071bd;
}
// .page-arena-7 {
//   --arena-color-1: #a20084;
// }

.page-arena-8 {
  --arena-color-1: #9BA0CC;
  // --arena-color-1: #e50050;
}

.page-arena-9 {
  --arena-color-1: #dbc12e;
}

.page-arena-10 {
  --arena-color-1: #EB6083;
}

.page-arena-11 {
  --arena-color-1: #00A39A;
}

.page-arena-12 {
  --arena-color-1: #DB9840;
}

.page-arena-13 {
  --arena-color-1: #9757C4;
}

.page-arena-14 {
  --arena-color-1: #A06288;
}

.page-arena-15 {
  --arena-color-1: #3B5B52;
}

.page-arena-title {
  position: relative;
  font-size: 40px;
  font-weight: bold;

  &.page-arena-title-8,
  &.page-arena-title-9 {
    font-size: 24px;
  }

  .page-arena-title-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 8px;
    left: 4px;
    color: var(--arena-color-1);
    filter: brightness(.9);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      width: auto !important;
      position: static !important;

      span.sub {
        padding-left: 10px;
        font-size: 0.6em !important;
      }
    }
  }

  .page-arena-title-text {
    --border: 2px;
    --border-minus: -2px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 4px;
    left: 0;
    color: white;
    text-shadow: var(--border-minus) var(--border-minus) 0 var(--arena-color-1), 0 var(--border-minus) 0 var(--arena-color-1), var(--border) var(--border-minus) 0 var(--arena-color-1), var(--border) 0 0 var(--arena-color-1), var(--border) var(--border) 0 var(--arena-color-1), 0 var(--border) 0 var(--arena-color-1), var(--border-minus) var(--border) 0 var(--arena-color-1), var(--border-minus) 0 0 var(--arena-color-1);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      width: auto !important;
      position: static !important;
      color: white !important;

      span.sub {
        padding-left: 10px;
        font-size: 0.6em !important;
      }
    }
  }
}

.page-island.page-arena {
  .btn-back {
    // display: none;
  }

  .page-arena-landing {
    // display: flex;
    // justify-content: center;
    button {
      background: transparent;
      border: none;
      width: 446px;
      height: 127px;
      position: absolute;
      bottom: 272px;
      left: 772px;
      // position: absolute;
    }
  }

  .page-arena-index {
    width: 1240px;
    height: 720px;
    margin: 15% auto 0;

    .page-arena-index-level {
      height: 100px;
      display: flex;
      padding-top: 80px;
      padding-bottom: 40px;
      align-content: center;
      justify-content: center;

      .page-arena-index-level-modal {
        position: absolute;
        z-index: 3000;
        width: 1252px;
        height: 611px;
        top: 320px;

        button {
          position: absolute;
          left: 570px;
          top: 520px;

          img {
            width: 161px;
            height: 69px;
          }
        }
      }

      .page-arena-index-level-self {
        width: 751px;
        height: 94px;
      }

      button {
        position: static;
        border: none;
        background: transparent;

        img {
          width: 256px;
          height: 106px;
        }
      }
    }

    .page-arena-index-chooser-wrapper {
      width: 1200px;
      // margin:
      // padding-left: 20px;
      height: 540px;
      padding-top: 80px;

      .page-arena-index-chooser {
        margin: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        a {
          position: relative;
          user-select: none;

          img {
            width: 260px;
            // height: 162px;
            padding: 8px 0;

            &.trophy {
              position: absolute;
              bottom: 20px;
              right: 20px;
              width: 34.6px;
              height: 68.2px;
            }
          }

          .info {
            position: absolute;
            width: 349px;
            height: 120px;
            background-repeat: no-repeat;
            // background: rgba(200,200,200, .9);
            // border-radius: 10px;
            // border: 2px solid white;
            z-index: 2;
            margin-left: -30px;
            top: 160px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.info-10,
            &.info-11,
            &.info-9 {
              top: -80px;
            }

            .info-row {
              // display: flex;
              // justify-content: space-around;
              // img {
              //   position: static;
              //   width: 21px;
              //   height: 40px;
              //   padding: 0;
              // }
              span {
                color: black;
                font-size: 20px;
                font-weight: bold;
                line-height: 40px;
                float: right;
                padding-right: 20px;

                &.base {
                  text-align: right;
                  width: 110px;
                }
              }
            }
          }
        }
      }
    }
  }

  .page-island-qna {
    // font-size: calc(var(--app-width) * .014 * var(--zoom));
    .page-island-qna-pagination {
      position: absolute;
      top: 30%;
      width: 60%;
      left: 20%;
      text-align: center;
      // background: red;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .page-island-qna-pagination-stage {
        // background-color: aqua;
        background-repeat: no-repeat;
        width: 306px;
        height: 47px;

        span {
          text-align: center;
        }

        .page-arena-title {
          span.sub {
            display: inline-block;
            clear: left;
          }
        }
      }

      .page-island-qna-pagination-progress {
        // background-color: yellow;
        flex: 4;

        svg {
          rect {
            stroke: white;
          }

          line {
            stroke: var(--arena-color-1);
          }
        }
      }

      .page-island-qna-pagination-index {
        // background-color: blue;
        flex: 1.25;
        font-weight: bold;
      }
    }

    .page-island-qna-title {
      position: absolute;
      top: 40%;
      width: 60%;
      left: 20%;
      height: 180px;
      font-weight: bold;
      font-size: calc(var(--app-width) * .018 * var(--zoom));
      color: #C19059;
    }

    .page-island-qna-question {
      position: absolute;
      top: 34%;
      bottom: 47%;
      width: 55%;
      left: 18%;
    }

    .page-island-qna-btn-img-popup-wrapper {
      top: 58%;
      left: 25%;
    }

    .page-island-qna-answer {
      position: absolute;
      top: 58%;
      bottom: 24%;
      width: 32%;
      left: 35%;

      button {
        border: none;
        background: none;
        background-size: cover;
        background-repeat: no-repeat;
        // width: 40%;
        // aspect-ratio: 300 / 107;
        width: calc(var(--app-width) * .15 * var(--zoom));
        height: calc(var(--app-width) * .051 * var(--zoom));
        border-radius: 9%;
        margin: 1%;

        span {
          color: white;
          // font-size: calc(var(--app-width) * .012 * var(--zoom));
        }

        &.text-small {
          span {
            font-size: 22px;
          }
        }

        &.selected {
          box-shadow: 0 0 calc(var(--app-width) * .005 * var(--zoom)) calc(var(--app-width) * .002 * var(--zoom)) #FF0;
        }
      }
    }

    .page-island-qna-submit {
      position: absolute;
      border: none;
      background: none;
      width: 8%;
      top: 78%;
      left: 46.5%;

      .page-island-qna-submit-spin {
        position: absolute;
        top: 15px;
        left: 72px;
        display: none;
      }

      &.disabled {
        filter: grayscale(1);

        .page-island-qna-submit-spin {
          display: block;
        }
      }
    }
  }
  @import './dialog-teacher';

  .dialog-teacher {
    top: 150px;
    left: 72px;
  }
}

div.dialog.page-island-arena-score {
  width: 1256px;
  height: 615px;
  left: 332px;
  right: 332px;
  top: 320px;

  &.page-island-qna-icon-get {
    .dialog-content {
      top: 130px;
      // background: red;
      width: 98%;
      left: 1%;

      .page-island-qna-icon-get-content {
        height: 200px;

        .page-island-qna-icon-get-content-title {
          text-align: center;
          font-weight: bold;
          height: 50px;
          color: black;
        }

        .page-island-qna-icon-get-content-flags {
          display: flex;
          height: 110px;
          justify-content: center;

          .page-island-qna-icon-get-content-flag {
            position: relative;

            img {
              height: 100px;
              margin: 0 5px;
            }

            > div {
              position: absolute;
              top: 30%;
              left: 30%;
              width: 40%;
              height: 40%;

              .page-arena-title {
                font-size: 12px;
                width: 100%;
                height: 100%;
                text-align: center;

                &.page-arena-title-8,
                &.page-arena-title-9 {
                  font-size: 6px;
                }
              }
            }
          }
        }

        .page-island-qna-icon-get-content-icon {
          height: 200px;
          text-align: center;

          img {
            height: 100%;
          }
        }
      }

      a {
        border: none;
        background: transparent;
        position: absolute;
        left: 560px;
        top: 380px;
        width: 161px;
        height: 69px;
      }
    }
  }

  &.page-island-arena-score {
    .dialog-content {
      .page-island-qna-score-content {
        .page-arena-title {
          position: absolute;
          width: 980px;
          height: 100px;
          top: -120px;
          left: 0;
          font-size: 82px;
          text-align: center;

          &.page-arena-title-12,
          &.page-arena-title-13,
          &.page-arena-title-14,
          &.page-arena-title-15,
          &.page-arena-title-8,
          &.page-arena-title-9 {
            font-size: 58px;
          }
        }
        height: auto;

        > div.ans1 {
          font-size: 120px;
          margin-left: 180px;
          color: black;
          font-weight: bold;
        }

        > div.ans {
          font-size: 56px;
          margin-left: 150px;
          padding-top: 25px;
          color: black;
          font-weight: bold;
        }
      }

      button {
        border: none;
        background: transparent;
        position: absolute;
        left: 420px;
        top: 280px;

        img {
          width: 161px;
          height: 69px;
        }
      }
    }
  }

  &.page-island-qna-stage-result {
    .dialog-content {
      .page-arena-title {
        position: absolute;
        width: 980px;
        height: 100px;
        top: -120px;
        left: 0;
        font-size: 82px;
        text-align: center;

        &.page-arena-title-12,
        &.page-arena-title-13,
        &.page-arena-title-14,
        &.page-arena-title-15,
        &.page-arena-title-8,
        &.page-arena-title-9 {
          font-size: 58px;
        }
      }

      span {
        position: absolute;
        font-size: 48px;
        color: black;
        font-weight: bold;
        display: inline-block;
        width: 320px;
        text-align: right;

        &.page-island-qna-stage-result-content-unit-topest {
          top: 20px;
          left: 560px;
        }

        &.page-island-qna-stage-result-content-correct-questions {
          top: 118px;
          left: 560px;
        }

        &.page-island-qna-stage-result-content-count-unit {
          font-size: 42px;
          width: 60px;
          top: 292px;
          left: 618px;
        }
      }

      button {
        border: none;
        background: transparent;
        position: absolute;
        left: 420px;
        top: 280px;

        img {
          width: 161px;
          height: 69px;
        }
      }

      button {
        top: 200px;
        // border: none;
        // background: transparent;
        // position: absolute;
        // left: 420px;
        // display: inline-block;
        // width: 161px;
        // height: 69px;
      }
    }
  }
}
