.main-swiper {
  position: absolute;
  width: calc(.8 * var(--zoom) * var(--app-width));
  left: 10%;
  top: 35%;
  width: 80%;
  // right: 10%;
  // bottom: 25%;
  .swiper-container {
    --swiper-navigation-size: 60px;
    position: static;

    .swiper-button-next {
      background-image: url("../../assets/svg_icon/pinkarrowbutton_right.svg");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      position: absolute;
      right: -5%;

      &:hover {
        filter: brightness(.5);
      }
    }

    .swiper-button-prev {
      background-image: url("../../assets/svg_icon/pinkarrowbutton_left.svg");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      position: absolute;
      left: -5%;

      &:hover {
        filter: brightness(.5);
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }

    .swiper-slide {
      width: 33.3% !important;

      > a {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        > div {
          width: 80%;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }

      &.coming-soon {
        > a {
          filter: grayscale(.9);
          pointer-events: none;
        }

        > span {
          &.coming-soon {
            font-size: 32px;
            font-weight: bold;
            transform: rotate(-30deg);
            display: inline-block;
            position: absolute;
            top: 200px;
            left: 164px;
            background: yellow;
            padding: 10px;
            transform-origin: center;
            color: black;
            user-select: none;
          }
        }
      }
    }
    @keyframes slide-center {
      from {
        width: 80%;
      }

      to {
        width: 100%;
      }
    }

    .swiper-slide.swiper-slide-active {
      > div {
        > div {
          animation-name: slide-center;
          animation-duration: 1s;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}
