.resizable-text-big {
  .modal-news {
    .resizable-text {
      h4 {
        font-size: 42px;
      }
      p {
        font-size: 38px;
      }
    }
  }
}
.resizable-text-normal {
  .modal-news {
    .resizable-text {
      h4 {
        font-size: 32px;
      }
      p {
        font-size: 28px;
      }
    }
  }
}
.resizable-text-small {
  .modal-news {
    .resizable-text {
      h4 {
        font-size: 22px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}
