div.dialog {
  position: absolute;
  top: 18%;
  left: 8%;
  right: 8%;
  bottom: 0;
  display: block;
  color: #70470b;
  background-repeat: no-repeat;

  .dialog-title {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    font-size: calc(var(--app-width) * .024 * var(--zoom));
    margin-top: 1.5%;
    user-select: none;
  }

  .dialog-tabs {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    height: 10%;
    top: 2.8%;
    left: 15%;
    user-select: none;

    .dialog-tab {
      flex: 1;
      margin: 0 1%;
      display: inline-block;
      border: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom center;
      text-align: center;

      .dialog-tab-title {
        font-size: calc(var(--app-width) * .018 * var(--zoom));
        margin: 1em 0 0.5em;
      }
    }
  }

  .dialog-btn-close {
    position: absolute;
    top: 2%;
    right: 5%;
    width: 5%;
    // height: 5%;
    user-select: none;

    // For <button />
    border: none;
    background: none;
    display: block;
    padding: 0%;
  }

  .dialog-content {
    position: absolute;
    font-size: calc(var(--app-width) * .015 * var(--zoom));
    top: 18%;
    left: 12%;
    width: 74%;
    height: 64%;
  }
}
