@import "../../scss/utils/bg-row";
@import "../../scss/utils/media-ar";

body.v4 {
  .modal-news {
    .modal-reel-cc {
      overflow: scroll;
    }

    .resizable-text-big {
      .modal-platform-intro {
        .resizable-text {
          p {
            font-size: 38px;
          }
        }
      }
    }

    .resizable-text-normal {
      .modal-platform-intro {
        .resizable-text {
          p {
            font-size: 28px;
          }
        }
      }
    }

    .resizable-text-small {
      .modal-platform-intro {
        .resizable-text {
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}
