@import "../../scss/utils/bg-row";
@import "../../scss/utils/media-ar";

body.v4 {
  .modal-leader-board {
    --size: 75px;
    // ar >= 1/1
    @media (min-aspect-ratio: 1/1), (aspect-ratio: 1/1) {
      --size-title-width: calc(600 * var(--ar-size-ratio) * 50);
      --size-title-lr-width: calc(200 * var(--ar-size-ratio) * 50);
      --size-title-height: calc(200 * var(--ar-size-ratio) * 50);
    }
    // ar < 1/1
    @media (max-aspect-ratio: 1/1) {
      --size-title-width: calc(600 * var(--ar-size-ratio) * 50);
      --size-title-lr-width: calc(200 * var(--ar-size-ratio) * 50);
      --size-title-height: calc(200 * var(--ar-size-ratio) * 50);
    }

    .modal-leader-board-list {
      margin: 80px 0 0 15%;
      width: 80%;
      height: calc(100% - 80px);
      @media only screen and (max-width: 1280px) {
        margin: 60px 0 0 8%;
        width: 90%;
      }
      @media only screen and (max-width: 800px) {
        margin: 40px 0 0;
        width: 100%;
      }

      .modal-leader-board-list-levels {
        display: flex;
        width: 100%;
        flex-direction: row;
        @include media-width-lte(1200px) {
          display: grid;
          grid-template-columns: auto auto;
        }
        @include bg-row('modal-leader-board-list-level', 2vw, 4.5vw, '../../assets-v4/v4_islands/course1_mainpage_brownbar_left.png', '../../assets-v4/v4_islands/course1_mainpage_brownbar_middle.png', '../../assets-v4/v4_islands/course1_mainpage_brownbar_right.png') {
          flex: 1;
          margin: 0.5vmin 1vmin;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .modal-leader-board-list-level-c {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .decoration {
              &:first-child {
                margin-left: -1vmin;
                margin-right: 2vmin;
              }

              &:last-child {
                margin-right: -1vmin;
                margin-left: 2vmin;
                z-index: 2;
              }
              width: 3vmin;
              height: 3vmin;
              background-image: url("/assets-v4/v4_islands/course1_mainpage_title_object_2.png");
              background-size: 3vmin;
              background-repeat: no-repeat;
              background-position: center;
            }

            .char {
              margin: 0 0.2vmin;
              color: white;
              font-size: 2.8vmin;
              @include media-width-between(760px, 790px) {
                font-size: 28px;
              }
            }
          }

          &.active {
            .decoration {
              animation-name: pagev4-island-main-title-topic-decoration;
              animation-duration: 10s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              @keyframes pagev4-island-main-title-topic-decoration {
                from {
                  transform: rotate(0deg);
                }

                to {
                  transform: rotate(360deg);
                }
              }
            }
          }

          &-2 {
            .modal-leader-board-list-level-2.modal-leader-board-list-level-l {
              background-image: url("/assets-v4/v4_islands/island_10/mainpage/course10_mainpage_purplebar_left.png");
            }

            .modal-leader-board-list-level-2.modal-leader-board-list-level-c {
              background-image: url("/assets-v4/v4_islands/island_10/mainpage/course10_mainpage_purplebar_middle.png");
            }

            .modal-leader-board-list-level-2.modal-leader-board-list-level-r {
              background-image: url("/assets-v4/v4_islands/island_10/mainpage/course10_mainpage_purplebar_right.png");
            }
          }

          &-3 {
            .modal-leader-board-list-level-3.modal-leader-board-list-level-l {
              background-image: url("/assets-v4/v4_islands/course3_mainpage_navyebar_left.png");
            }

            .modal-leader-board-list-level-3.modal-leader-board-list-level-c {
              background-image: url("/assets-v4/v4_islands/course3_mainpage_navyebar_middle.png");
            }

            .modal-leader-board-list-level-3.modal-leader-board-list-level-r {
              background-image: url("/assets-v4/v4_islands/course3_mainpage_navyebar_right.png");
            }
          }

          &-4 {
            .modal-leader-board-list-level-4.modal-leader-board-list-level-l {
              background-image: url("/assets-v4/v4_islands/course2_mainpage_bar_left.png");
            }

            .modal-leader-board-list-level-4.modal-leader-board-list-level-c {
              background-image: url("/assets-v4/v4_islands/course2_mainpage_bar_middle.png");
            }

            .modal-leader-board-list-level-4.modal-leader-board-list-level-r {
              background-image: url("/assets-v4/v4_islands/course2_mainpage_bar_right.png");
            }
          }
        }
      }

      .modal-leader-board-list-levels-no_result {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: calc(100% - 20vmin);
        a {
          font-size: 1.2em;
        }
      }

      .modal-leader-board-student {
        background: white;
        border: 2px solid #B22F1C;
        // height: 50px;
        // line-height: 50px;
        border-radius: 25px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        font-size: 26px;
        margin-bottom: 8px;
        margin-right: 10px;
        @media only screen and (max-width: 1280px) {
          height: 120px;
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-template-areas: 'index avator names names names names names' '. . . . . star score';
        }
        @media only screen and (max-width: 800px) {
          height: auto;
          border-radius: 5px;
          line-height: 18px;
          font-size: 16px;
          grid-template-columns: repeat(4, 1fr);
          grid-template-areas: 'index index avator avator' 'names names names names' 'star star score score';
        }

        .modal-leader-board-student-index {
          grid-area: index;
          width: 90px;
          text-align: center;
        }

        .modal-leader-board-student-avator {
          grid-area: avator;
          width: 60px;
          height: 60px;
          margin-right: 15px;
          // background-image: url("../../assets-v4/20230706_main/mainpage_dialog/leaderboard/mainpage_dialog_leaderboard8.png");
          img {
            width: 100%;
            height: 100%;
          }
        }

        .modal-leader-board-student-names {
          grid-area: names;
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          @media only screen and (max-width: 800px) {
            flex-direction: column;
          }

          .modal-leader-board-student-name {
            overflow: hidden;
            flex: 1;
          }

          .modal-leader-board-student-seperator {
            margin-right: 2px;
          }

          .modal-leader-board-student-school {
            margin-right: 2px;
            @media only screen and (max-width: 800px) {
              clear: left;
            }
          }

          .modal-leader-board-student-class-no {
            margin-right: 2px;
          }
        }

        .modal-leader-board-student-star {
          grid-area: star;
          width: 36px;
          height: 36px;
          background-image: url("../../assets-v4/20230706_main/mainpage_dialog/leaderboard/mainpage_dialog_leaderboard2.png");
          background-repeat: no-repeat;
          background-size: contain;
          margin: 0 10px;
        }

        .modal-leader-board-student-score {
          grid-area: score;
          width: 100px;
          margin: 0 10px;
          @media only screen and (max-width: 1280px) {
            text-align: right;
          }
          @media only screen and (max-width: 800px) {
            width: auto;
          }
        }
      }
    }

    .modal-leader-board-btn-desc {
      border: 2px solid black;
      font-size: 1.5em;
      padding: 10px;
      border-radius: 1.5em;
      background-color: white;
      position: absolute;
      right: 5%;
      top: 5%;
      @include media-width-lte(800px) {
        font-size: 1em;
        top: auto;
        right: auto;
        left: 10%;
        bottom: 3%;
      }
    }

    .modal-leader-board-kaka-1 {
      position: absolute;
      top: var(--size);
      height: 50vh;
      width: calc(50vh / 2139 * 589);
      left: calc(50vh / 2139 * -589 / -3);
      background-image: url("../../assets-v4/20230706_main/mainpage_dialog/leaderboard/mainpage_dialog_leaderboard1.png");
      z-index: 4;
      @include media-width-lte(1280px) {
        top: auto;
        bottom: 10px;
      }
      @include media-width-lte(800px) {
        top: auto;
        transform: scale(.6);
        left: 0;
        bottom: 10px;
      }
    }

    .modal-title-row {
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      top: 8%;
      width: 100%;

      .title {
        font-size: 40px;
        margin-right: 20px;
      }
      // .modal-title {
      @include bg-row('modal-title', 80px, 80px, '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_left.png', '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_mid.png', '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_right.png') {
        width: 300px;
        display: flex;
        flex-direction: row;
        margin: 0 10px;

        .modal-title-c {
          font-size: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.modal-title-school {
          .modal-title-l {
            background-image: url("../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_pinkb_left.png");
          }

          .modal-title-c {
            background-image: url("../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_pinkb_mid.png");
          }

          .modal-title-r {
            background-image: url("../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_pinkb_left.png");
            transform: scaleX(-1);
          }
        }
      }
      @include media-width-lte(1200px) {
        left: -.5%;

        .title {
          font-size: 30px;
          margin-right: 10px;
        }
        @include bg-row('modal-title', 60px, 60px, '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_left.png', '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_mid.png', '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_right.png') {
          width: 240px;
          margin: 0 6px;

          .modal-title-c {
            font-size: 30px;
          }
        }
      }
      @include media-width-lte(800px) {
        .title {
          font-size: 20px;
          margin-right: 5px;
        }
        @include bg-row('modal-title', 40px, 40px, '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_left.png', '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_mid.png', '../../assets-v4/20230711_joe_amendment/leaderboardtab/leaderboardtab_blueb_right.png') {
          width: 120px;
          margin: 0 2px;

          .modal-title-c {
            font-size: 20px;
          }
        }
      }
    }

    .modal-leader-board-body {
      // background: rgba(100,100,100,.5);
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
}
