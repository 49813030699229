.lottie-svg {
  svg {
    // -webkit-filter: drop-shadow(4px 4px 3px );
    filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, .7));
    // filter: drop-shadow(var(--svg-shadow-offset-x) var(--svg-shadow-offset-y) var(--svg-shadow-blur-radius) var(--svg-shadow-color));
  }
}

svg * {
  user-select: none;
  pointer-events: none;
}
