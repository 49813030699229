@import "../../scss/utils/bg-row";
@import "../../scss/utils/media-ar";

body.v4 {
  .modal-platform-info {
    .resizable-text-big.resizable-text {
      p {
        font-size: 28px;
      }
    }

    .resizable-text-normal.resizable-text {
      p {
        font-size: 20px;
      }
    }

    .resizable-text-small.resizable-text {
      p {
        font-size: 14px;
      }
    }
  }
}
