/* width */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
