@import "../../scss/utils/media-ar";
@import "../../scss/utils/scrollbar";

body.v4 {
  .modal-reel {
    --size: 75px;
    --size-button-height: calc(232 * var(--ar-size-ratio) * 30);
    --size-volume-bar-handle: calc(194 * var(--ar-size-ratio) * 36);
    --modal-width: 90vw;
    --modal-height: 90vh;
    --modal-left: 5vw;
    --modal-top: 5vh;
    --img-reel: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/setting_reel_2t.png");
    --img-bg: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/mainpage_dialog_box_150x150.png");
    --img-corner: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/mainpage_dialog_setting_corner.png");
    --img-tb: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/mainpage_dialog_setting_topbottom.png");
    --img-lr: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/mainpage_dialog_setting_side.png");

    &-sm {
      --modal-width: 60vw;
      --modal-height: 60vh;
      --modal-left: 20vw;
      --modal-top: 20vh;
    }

    &-md {
      --modal-width: 80vw;
      --modal-height: 80vh;
      --modal-left: 10vw;
      --modal-top: 10vh;
    }
    // ar >= 1/1
    @include media-ar-gte(1,1) {
      --size-title-width: calc(800 * var(--ar-size-ratio) * 40);
      --size-title-lr-width: calc(198 * var(--ar-size-ratio) * 40);
      --size-title-height: calc(288 * var(--ar-size-ratio) * 40);
    }
    // ar < 1/1
    @include media-ar-lt(1,1) {
      --modal-width: 98vw;
      --modal-height: 98vh;
      --modal-left: 1vw;
      --modal-top: 1vh;
      --size-title-width: calc(800 * var(--ar-size-ratio) * 30);
      --size-title-lr-width: calc(198 * var(--ar-size-ratio) * 30);
      --size-title-height: calc(288 * var(--ar-size-ratio) * 30);
    }
    @include media-height-lt(800px) {
      --modal-height: 98vh;
      --modal-top: 1vh;
    }
    @include media-width-lt(1200px) {
      --modal-width: 98vw;
      --modal-left: 1vw;
    }
    --size-close: calc(var(--size) / 3 * 2);
    --pos-close: calc(var(--size-close) / 3);
    position: absolute;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    width: var(--modal-width);
    height: var(--modal-height);
    left: var(--modal-left);
    top: var(--modal-top);

    button {
      border: none;
      background-color: transparent;
      background-size: contain;
    }

    div {
      background-size: contain;
    }

    .modal-reel-main {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: calc(var(--modal-height) / 1563 * 144 * .8) 0;
      height: calc(var(--modal-height) - var(--modal-height) / 1563 * 144 * 1.6);
      background-repeat: repeat;
      background-size: 50px;
      background-image: var(--img-bg);
      position: relative;
      pointer-events: none;

      .modal-reel-t {
        display: flex;
        height: var(--size);

        .modal-reel-tl {
          width: var(--size);
          height: var(--size);
          background-image: var(--img-corner);
        }

        .modal-reel-tc {
          flex: 1;
          height: var(--size);
          background-repeat: repeat-x;
          background-image: var(--img-tb);
          margin: 0 -2px;
        }

        .modal-reel-tr {
          width: var(--size);
          height: var(--size);
          background-image: var(--img-corner);
          transform: scaleX(-1);
        }
      }

      .modal-reel-c {
        display: flex;
        flex: 1;
        height: calc(var(--modal-height) - var(--modal-height) / 1563 * 144 * 2 - var(--size) * 2);

        .modal-reel-cl {
          width: var(--size);
          background-repeat: repeat-y;
          background-image: var(--img-lr);
          margin: -2px 0;
        }

        .modal-reel-cc {
          flex: 1;
          // width: var(--size);
          // background-color: white;
          background-size: var(--size);
          background-repeat: repeat;
          margin: -20px;
          pointer-events: all;
        }

        .modal-reel-cr {
          width: var(--size);
          background-repeat: repeat-y;
          background-image: var(--img-lr);
          transform: scaleX(-1);
          margin: -2px 0;
        }
      }

      .modal-reel-b {
        display: flex;
        height: var(--size);

        .modal-reel-bl {
          width: var(--size);
          height: var(--size);
          background-image: var(--img-corner);
          transform: scaleY(-1);
        }

        .modal-reel-bc {
          flex: 1;
          height: var(--size);
          background-repeat: repeat-x;
          background-image: var(--img-tb);
          transform: scaleY(-1);
          margin: 0 -2px;
        }

        .modal-reel-br {
          width: var(--size);
          height: var(--size);
          background-image: var(--img-corner);
          transform: scaleX(-1) scaleY(-1);
        }
      }

      .modal-reel-close {
        position: absolute;
        width: var(--size-close);
        height: var(--size-close);
        right: var(--pos-close);
        top: var(--pos-close);
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting3.png");

        &:hover, &:active {
          transform: scale(.9);
        }
      }
    }

    .modal-reel-start {
      width: calc(var(--modal-height) / 1563 * 144);
      height: var(--modal-height);

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: var(--img-reel);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .modal-reel-end {
      width: calc(var(--modal-height) / 1563 * 144);
      height: var(--modal-height);

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: var(--img-reel);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    @media (max-aspect-ratio: 10/8) {
      flex-direction: column;

      .modal-reel-start {
        width: var(--modal-width);
        height: calc(var(--modal-width) / 1563 * 144);
        z-index: 2;

        &:before {
          width: calc(var(--modal-width) / 1563 * 144);
          height: var(--modal-width);
          transform: rotate(270deg) translateX(calc(var(--modal-width) / 1563 * -144));
          transform-origin: left top;
        }
      }

      .modal-reel-main {
        margin: -2px calc(var(--modal-height) / 1563 * 144 / 1.6);

        .modal-reel-body {
          .modal-reel-body-buttons {
            gap: 10px;
          }
        }
      }

      .modal-reel-end {
        width: var(--modal-width);
        height: calc(var(--modal-width) / 1563 * 144);
        z-index: 2;

        &:before {
          width: calc(var(--modal-width) / 1563 * 144);
          height: var(--modal-width);
          transform: rotate(270deg) translateX(calc(var(--modal-width) / 1563 * -144));
          transform-origin: left top;
        }
      }
    }

    &-green {
      --img-reel: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/green/treasureaward_reel_2t_green.png");
      --img-corner: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/green/mainpage_dialog_setting_corner_green.png");
      --img-tb: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/green/mainpage_dialog_setting_topbottom_green.png");
      --img-lr: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/green/mainpage_dialog_setting_side_green.png");
      @include scrollbar(16px, 12px, #459B45, #FFD8A7);
    }

    &-blue {
      --img-reel: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/blue/treasuredirection_reel_2t_blue.png");
      --img-corner: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/blue/mainpage_dialog_setting_corner_blue.png");
      --img-tb: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/blue/mainpage_dialog_setting_topbottom_blue.png");
      --img-lr: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/blue/mainpage_dialog_setting_side_blue.png");
      @include scrollbar(16px, 12px, #4A79A9, #FFD8A7);
    }

    &-inner-gold {
      --img-corner: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/inner-gold/mainpage_dialog_setting_corner_gold.png");
      --img-tb: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/inner-gold/mainpage_dialog_setting_topbottom_gold.png");
      --img-lr: url("../../assets-v4/20230711_joe_amendment/mainpage_dialog_setting/inner-gold/mainpage_dialog_setting_side_gold.png");
    }
  }
}
