.App {
  // aspect-ratio: 16 / 9;
  overflow: hidden;
  position: relative;
  width: 1920px;
  height: 1080px;
}

#root {
  --app-width: 1920px;
  --app-height: 1080px;
}
//
// Wider than 16x9
@media (min-aspect-ratio: 16/9) {
  // #root {
  //   --app-width: calc(100vh / 9 * 16);
  //   --app-height: 100vh;
  // }
  body {
    // background-color: black;
  }

  .App {
    // height: calc(100vh * var(--zoom));
    // width: calc(100vh * var(--zoom) / 9 * 16);
    // margin: 0 auto;
  }
}
// Taller than 16x9
@media (max-aspect-ratio: 16/9) {
  // #root {
  //   --app-width: 100vw;
  //   --app-height: calc(100vh / 16 * 9);
  // }
  body {
    // background-color: orange;
  }

  .App {
    // width: calc(100vw * var(--zoom));
    // height: calc(100vw * var(--zoom) / 16 * 9);
    // margin: auto 0;
  }
}
