@mixin bg-column($name, $width, $height, $bgImgT, $bgImgC, $bgImgB) {
  .#{$name} {
    display: flex;
    flex-direction: column;
    // height: $height;

    .#{$name}-t {
      width: 100%;
      height: $height;
      background-image: url("#{$bgImgT}");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .#{$name}-c {
      flex: 1;
      width: 100%;
      background-image: url("#{$bgImgC}");
      background-repeat: repeat-y;
      background-size: contain;
    }

    .#{$name}-b {
      width: 100%;
      height: $height;
      background-image: url("#{$bgImgB}");
      background-repeat: no-repeat;
      background-size: cover;
    }

    @content;
  }
}
