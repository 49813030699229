body.zoom-gt-1 {
  overflow: scroll;
}

body.zoom-gt-1 #root {
}

body.zoom-lte-1 #root {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // height: 100vh;
}
