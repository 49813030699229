.scrollbar-vertical {
  // position: fixed;
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  // white-space:nowrap;
  // height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;
  pointer-events: all;

  &:after {
    min-height: calc(100% + 1px);
  }
}
