#root {
  //
  --zoom: 1;
  --app-width: 1;
  --app-height: 1;
  //
  --header-top: 2%;
  --header-height: 10%;
  --header-left-right: 2%;
  --header-marquee-duration: 5s;
  //
  --svg-shadow-offset-x: 2%;
  --svg-shadow-offset-y: 2%;
  --svg-shadow-blur-radius: 2%;
  --svg-shadow-color: rgba(0, 0, 0, .7);
}
@import "./scss/_scrollbar";
@import "./scss/_svg";
@import "./scss/_zoom";
@import "./scss/_aspect-ratio";
@import "./scss/_aspect-box";
@import "./scss/_spinkit"; // --
// @TODO
@import "./scss/project-ui/_main";
@import "./scss/project-ui/_form";
@import "./scss/project-ui/_dialog";
@import "./scss/project-ui/_animated-css-transition";
@import "./scss/project-ui/_scrollbar-vertical";
//
// @font-face {
//   font-family: 'the-font';
//   src: url("./scss/font.woff2") format('woff2'),
//     url("./scss/font.ttf") format('truetype');
// }
@import url(https://fonts.googleapis.com/earlyaccess/cwtexyen.css); // @import url('https://fonts.googleapis.com/css2?family=Kosugi+Maru&family=M+PLUS+Rounded+1c:wght@700;900&family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK&family=Noto+Sans+TC&display=swap');

body {
  overflow: hidden;
  overflow-y: auto;
  cursor: url('./assets/png_icon/mouse.cur'), auto; // font-family: 'the-font';
  // font-family: 'Kosugi Maru', sans-serif;
  // font-family: 'M PLUS Rounded 1c', sans-serif;
  // font-family: 'Varela Round', sans-serif;
  font-family: 'cwTeXYen', 'Varela Round', 'M PLUS Rounded 1c', sans-serif;
}

html {
  // overflow-y: scroll;
}

*,
a:hover {
  cursor: url('./assets/png_icon/mouse.cur'), auto;
}

.App {
  transform-origin: top left;

  .modal-container {
    z-index: 5000;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.modal-container-no-bg {
      background-color: transparent;
    }

    &.modal-profile-icon-select {
      .dialog {
        top: 42%;
        left: 20%;
        right: 20%;
        bottom: 0;

        .dialog-btn-close {
          top: -4%;
          right: -2%;
        }

        .wrapper {
          width: 92%;
          height: 75%;
          top: 5%;
          position: relative;
          left: 4%;
          right: 5%;
          bottom: 5%;

          .image-grid {
            // display: grid;
            // grid-template-columns: repeat(8, 1fr);
            img {
              width: 12.5%;

              &:hover {
                filter: drop-shadow(0px 0px 8px yellow);
              }

              &.disabled {
                filter: grayscale(.8);
              }

              &.disabled:hover {
                filter: grayscale(.8);
              }
            }
          }
        }
      }
    }

    &.modal-profile {
      .dialog {
        left: 15%;
        right: 15%;
        top: 12%;
        bottom: 5%;
        display: flex;
        flex-direction: column;
        padding: 5%;

        .dialog-btn-close {
          top: 5%;
        }

        .header {
          flex: 1;
          display: flex;
          font-size: calc(var(--app-width) * .025 * var(--zoom));
          font-weight: bold;

          .left {
            flex: 1;

            img {
              width: 100%;
            }
          }

          .right {
            flex: 5;
            display: flex;
            flex-direction: column;
            margin: 0 2%;

            .user {
              background-repeat: no-repeat;
              // aspect-ratio: 707 / 77;
              width: calc(var(--app-width) * .5 * var(--zoom));
              height: calc(var(--app-height) * .1 * var(--zoom));
              display: flex;
              justify-content: center;
              align-items: center;

              .name {}

              .schoolName {
                font-size: calc(var(--app-width) * .018 * var(--zoom));
              }

              .classNo {
                font-size: calc(var(--app-width) * .018 * var(--zoom));
              }
            }

            .marks {
              display: flex;
              flex-direction: row;

              & > div {
                flex: 1;
                // aspect-ratio: 355 / 77;
                width: calc(var(--app-width) * .16 * var(--zoom));
                height: calc(var(--app-height) * .066 * var(--zoom));
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: calc(var(--app-width) * .022 * var(--zoom));

                &.star {
                  img {
                    height: 60%;
                  }

                  span {
                    margin: 0 5%;
                  }
                }

                &.score {}

                &.level {}
              }
            }
          }
        }

        .my-units-result {
          flex: 3;
          padding-top: 2%;
          padding-left: 15%;
          font-size: calc(var(--app-width) * .020 * var(--zoom));
          font-weight: bold;
          color: black;
          height: 400px;

          .mark {
            .title {}

            .details {
              display: flex;
              justify-content: space-around;
              align-items: center;
              flex: 12;

              .progress {
                flex: 7;
                display: flex;

                .progress-bar {
                  background-repeat: no-repeat;
                  flex: 7;
                  // aspect-ratio: 459 / 44;
                  width: calc(var(--app-width) * .3 * var(--zoom));
                  height: calc(var(--app-height) * .05 * var(--zoom));
                  // background-size: cover;
                }

                .progress-percent {
                  flex: 2;
                  text-align: center;
                }
              }

              .star {
                flex: 2;
                display: flex;
                justify-content: space-around;
                align-items: center;

                img {
                  width: 20%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  cursor: url('./assets/png_icon/mouse.cur'), auto !important;
}

.footer-slide {
  // position: relative;
  // padding-top: 10%;
  &:hover {
    .footer-slide-wrapper {
      img.popup {
        display: block;
      }
      // img.preview {
      //   bottom: 25px;
      // }
    }
  }

  .footer-slide-wrapper {
    display: block;
    // width: 120%;
    // height: 120px;
    // margin-left: -10%;
    padding-top: 170%;
    @keyframes clicked-effect {
      from {
        bottom: 10%;
      }

      to {
        bottom: 15%;
      }
    }

    &.clicked {
      img.preview {
        animation-name: clicked-effect;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-iteration-count: 2;
      }
    }

    img.popup {
      display: none;
      top: 0;
      height: 40px;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 50%;
      right: 0;
      text-align: center;
      transform-origin: center;
      transform: translateX(-50%);
    }

    img.preview {
      width: 80%;
      display: block;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 10%;
      transform: scale(1.5);
      transform-origin: bottom;
      width: 125px;
      height: 109px;
    }

    img.line {
      width: 100%;
      bottom: 0;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

@import "./AppV4";
