.page-settings-technical-support {
  .form {
    position: absolute;
    top: 20%;
    width: 60%;
    height: 60%;
    left: 25%;
  }

  .page-settings-technical-support-submit {
    position: absolute;
    left: 50%;
    width: 20%;
    border: none;
    background: transparent;

    &[disabled] {
      filter: grayscale(1);
    }
  }
}
