.form {
  font-size: calc(var(--app-width) * .018 * var(--zoom));

  input,
  select,
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    border: none;
    line-height: 100%;
    height: 78%;
    width: 94%;
    padding: 1% 3%;
    background: transparent;
    font-size: calc(var(--app-width) * .018 * var(--zoom));

    &:focus-visible {
      outline: none;
    }
  }

  textarea {
    margin: 2% 1%;
    height: 88%;
    width: 88%;
    resize: none;
  }
  // position: absolute;
  // top: 30%;
  // width: 50%;
  // left: 25%;
  // background: red;
  .input-group {
    display: flex;
    flex-direction: row;

    .input-label {
      font-size: calc(var(--app-width) * .018 * var(--zoom));
      flex: 1;
      display: flex;
      align-items: center;
    }

    .input-field {
      flex: 2;
      background-repeat: no-repeat;
      background-position: top left;
      // aspect-ratio: 300 / 31;
      width: calc(var(--app-width) * .5 * var(--zoom));
      height: calc(var(--app-height) * .06 * var(--zoom));

      &.input-field-textarea {
        // aspect-ratio: 300 / 142;
        width: calc(var(--app-width) * .5 * var(--zoom));
        height: calc(var(--app-height) * .275 * var(--zoom));
      }

      &.input-field-select {
        position: relative;

        button {
          width: 8%;
          position: absolute;
          right: 4%;
          border: none;
          background: transparent;
          top: 25%;
        }

        .input-field-select-options {
          display: none;
          position: absolute;
          top: 90%;
          width: 100%;
          // aspect-ratio: 698 / 186;
          width: calc(var(--app-width) * .32 * var(--zoom));
          height: calc(var(--app-height) * .2 * var(--zoom));
          background-position: left top;
          background-repeat: no-repeat;
          flex-direction: column;
          align-items: center;

          &.input-field-select-options-on {
            display: flex;
          }

          .input-field-select-option {
            width: 90%;
            // aspect-ratio: 698 /64;
            width: calc(var(--app-width) * .3 * var(--zoom));
            height: calc(var(--app-height) * .05 * var(--zoom));
            text-align: center;
            background-position: left top;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;

            &:hover {
              background-image: var(--option-bg);
            }
          }
        }
      }
    }

    img {
      width: calc(var(--app-width) * .018 * var(--zoom));
    }
  }

  &.form-vertical {
    .input-group {
      display: block;
      // flex-direction: column;
    }

    .input-field {
      // flex: 1;
      width: calc(var(--app-width) * .4 * var(--zoom));
      height: calc(var(--app-width) * .042 * var(--zoom));
    }
  }
}
