body.v4 {
  /* width */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #C92B2B;
    width: 12px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #FFA910;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #FFA910;
  }
  scrollbar-width: thin;
  scrollbar-color: #FFA910 #C92B2B;
}
