@mixin bg-toggle($name, $bgImg, $bgImgSelect) {
  .#{$name} {
    background-image: url($bgImg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &:active,
    &:hover {
      background-image: url($bgImgSelect);
    }
    @content;
  }
}
