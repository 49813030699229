body.v4 {
  .modal-profile {
    --size: 75px;
    // --size-title-width: calc(800 * var(--ar-size-ratio) * 40);
    --size-title-lr-width: calc(198 * var(--ar-size-ratio) * 40);
    // --size-title-height: calc(288 * var(--ar-size-ratio) * 40);
    --size-subtitle-lr-width: calc(195 * var(--ar-size-ratio) * 30);
    --size-subtitle-height: calc(288 * var(--ar-size-ratio) * 30);
    // --size-button-height: calc(232 * var(--ar-size-ratio) * 30);
    --size-profile-body-info-height: calc(232 * var(--ar-size-ratio) * 50);
    --size-profile-body-score-height: calc(232 * var(--ar-size-ratio) * 50);

    .modal-profile-body {
      // background: rgba(100,100,100,.5);
      width: 100%;
      height: 100%;
      gap: 15px;
      @media (max-aspect-ratio: 1/1) {
        gap: 10px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div {
        width: 100%;

        &.modal-profile-body-info {
          height: var(--size-profile-body-info-height);
          display: flex;
          flex-direction: row;
          justify-content: center;
          // @media (max-aspect-ratio: 1/1) {
          //   height: calc(2 * var(--size-profile-body-info-height));
          //   flex-direction: column;
          // }
          .modal-profile-body-info-avator {
            width: var(--size-profile-body-info-height);
            height: var(--size-profile-body-info-height);
            background: #B12F1C;
            border-radius: 50%;
            position: relative;

            &:before {
              content: '';
              display: block;
              padding: 5px;
              width: calc(100% - 12px);
              height: calc(100% - 12px);
              border: 1px solid #F5C7A1;
              background: #B12F1C;
              border-radius: 50%;
            }

            img {
              object-fit: contain;
              position: absolute;
              top: 0;
              padding: 10%;
              width: 80%;
              height: 80%;
            }
          }

          .modal-profile-body-info-name {
            background-repeat: no-repeat;
            display: flex;
            flex-direction: row;
            flex: 1;
            position: relative;

            .modal-profile-body-info-name-l {
              width: var(--size-title-lr-width);
              height: 100%;
              background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting5.png");
            }

            .modal-profile-body-info-name-c {
              flex: 1;
              background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting6.png");
              // display: flex;
              // align-items: center;
              // justify-content: center;
              font-size: var(--font-size-header);
              text-align: center;

              span {
                line-height: var(--size-profile-body-info-height);
                height: var(--size-profile-body-info-height);
                @media (max-aspect-ratio: 1/1) {
                  display: block;
                  line-height: calc(var(--size-profile-body-info-height) / 5 * 2);
                  height: calc(var(--size-profile-body-info-height) / 5 * 2);
                }

                &.modal-profile-body-info-name-name {
                  @media (max-aspect-ratio: 1/1) {
                    padding-top: 10px;
                  }
                  @media (max-aspect-ratio: 1/1) {
                    display: none;
                  }
                }

                &.modal-profile-body-info-name-school_name {
                  font-size: var(--font-size-base);
                  @media (max-aspect-ratio: 1/1) {
                    display: none;
                  }
                }
              }
            }

            .modal-profile-body-info-name-r {
              width: var(--size-title-lr-width);
              height: 100%;
              background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting22.png");
            }

            .modal-profile-body-info-name-layer {
              position: absolute;
              text-align: center;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              display: none;
              @media (max-aspect-ratio: 1/1) {
                display: flex;
              }

              span {
                display: block;

                &.modal-profile-body-info-name-name {
                  font-size: 1.2em;
                }

                &.modal-profile-body-info-name-school_name {}
              }
            }
          }
        }

        &.modal-profile-body-score {
          // height: var(--size-profile-body-score-height);
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 15px;
          @media (max-aspect-ratio: 1/1) {
            height: calc(1.8 * var(--size-profile-body-score-height));
            flex-direction: column;
          }

          div {
            height: var(--size-subtitle-height);
            line-height: var(--size-subtitle-height);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .modal-profile-body-score-item-l {
              width: var(--size-subtitle-lr-width);
              height: 100%;
              background-image: url("../../assets-v4/20230706_main/mainpage_dialog/studentaccount/mainpage_dialog_studentaccount10.png");
              background-repeat: no-repeat;
            }

            .modal-profile-body-score-item-c {
              flex: 1;
              background-image: url("../../assets-v4/20230706_main/mainpage_dialog/studentaccount/mainpage_dialog_studentaccount11.png");
              font-size: var(--font-size-header);
              text-align: center;
            }

            .modal-profile-body-score-item-r {
              width: var(--size-subtitle-lr-width);
              height: 100%;
              background-image: url("../../assets-v4/20230706_main/mainpage_dialog/studentaccount/mainpage_dialog_studentaccount12.png");
              background-repeat: no-repeat;
            }

            &.modal-profile-body-score-stars {
              .modal-profile-body-score-stars-star {
                content: '';
                display: inline-block;
                width: calc(var(--size-subtitle-height) / 2);
                height: calc(var(--size-subtitle-height) / 2);
                background-image: url("../../assets-v4/20230706_main/mainpage_dialog/studentaccount/mainpage_dialog_studentaccount5.png");
                background-size: contain;
              }
            }

            &.modal-profile-body-score-score {
              .modal-profile-body-score-score-title {
                display: none;
                @media (max-aspect-ratio: 1/1) {
                  display: inline;
                }
              }
            }

            &.modal-profile-body-score-title {
              @media (max-aspect-ratio: 1/1) {
                display: none;
              }
            }
          }
        }

        &.modal-profile-body-units {
          flex: 1;
          height: calc(100% - var(--size-profile-body-score-height) - var(--size-profile-body-info-height) - 60px) !important;
          @media (max-aspect-ratio: 1/1) {
            height: calc(100% - 1.8 * var(--size-profile-body-score-height) - var(--size-profile-body-info-height) - 60px) !important;
          }

          .modal-profile-body-units-unit {
            &:last-child {
              margin-bottom: 20px;
            }

            .modal-profile-body-units-unit-title {
              font-size: var(--font-size-base);
            }

            .modal-profile-body-units-unit-result {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              @media (max-aspect-ratio: 1/1) {
                display: grid;
                grid-template-areas: 'bar bar' 'percent stars';
              }

              .modal-profile-body-units-unit-result-bar {
                flex: 4;
                grid-area: bar;

                .modal-profile-body-units-unit-result-bar-wrapper {
                  border: 2px solid #B45152;
                  background: white;
                  height: calc(var(--size-subtitle-height) / 2);
                  border-radius: calc(var(--size-subtitle-height) / 2 / 2);
                  @media (max-aspect-ratio: 1/1) {
                    height: auto;
                    width: 80%;
                  }

                  line {
                    stroke: #006837;
                    stroke-linecap: round;
                    stroke-width: 20;
                  }
                }
              }

              .modal-profile-body-units-unit-result-percent {
                grid-area: percent;
                flex: 1.5;
                text-align: center;
                font-size: calc(.8 * var(--font-size-header));
              }

              .modal-profile-body-units-unit-result-stars {
                grid-area: stars;
                flex: 1.5;
                display: flex;
                flex-direction: row;
                text-align: center;

                .modal-profile-body-units-unit-result-stars-star {
                  width: calc(var(--size-subtitle-height) / 2);
                  height: calc(var(--size-subtitle-height) / 2);
                  background-image: url("../../assets-v4/20230706_main/mainpage_dialog/studentaccount/mainpage_dialog_studentaccount5.png");
                  background-size: contain;
                  background-repeat: no-repeat;
                }

                .modal-profile-body-units-unit-result-stars-total {
                  font-size: calc(.8 * var(--font-size-header));
                }
              }
            }
          }
        }
      }
    }
  }
}
