@mixin animationCss($name: pulse, $duration: 3s, $iteration-count: infinit, $timing-function: linear) {
  animation-name: $name;
  animation-duration: $duration;
  animation-iteration-count: $iteration-count;
  animation-timing-function: linear;
}
@mixin animationCssOnce($name: pulse, $duration: 3s) {
  animation-name: $name;
  animation-duration: $duration;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
