@mixin bg-row($name, $width, $height, $bgImgL, $bgImgC, $bgImgR) {
  .#{$name} {
    display: flex;
    height: $height;

    .#{$name}-l {
      width: $width;
      height: 100%;
      background-image: url("#{$bgImgL}");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .#{$name}-c {
      flex: 1;
      height: 100%;
      background-image: url("#{$bgImgC}");
      background-repeat: repeat-x;
      background-size: contain;
    }

    .#{$name}-r {
      width: $width;
      height: 100%;
      background-image: url("#{$bgImgR}");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right;
    }

    @content;
  }
}

@mixin bg-row-abs($name, $width, $height, $bgImgL, $bgImgC, $bgImgR) {
  .#{$name} {
    position: relative;
    height: $height;

    .#{$name}-l {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: $width;
      background-image: url("#{$bgImgL}");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .#{$name}-c {
      position: absolute;
      flex: 1;
      left: calc(#{$width} - 2px);
      right: calc(#{$width} - 2px);
      top: 0;
      bottom: 0;
      background-image: url("#{$bgImgC}");
      background-repeat: repeat-x;
      background-size: contain;
    }

    .#{$name}-r {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: $width;
      background-image: url("#{$bgImgR}");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right;
    }

    @content;
  }
}
