@import "../../scss/utils/bg-row";
@import "../../scss/utils/bg-column";
@import "../../scss/utils/bg-toggle";
@import "../../scss/utils/scrollbar";
@import "../../scss/utils/media-ar";
@mixin header-main-border() {
  height: var(--header-main-border-height);
  background: white;
  border: 1px solid #006837;
  border-radius: calc(.5 * var(--header-main-border-height));
  display: flex;
  justify-content: center;
  align-items: center;
}

body.v4 {
  --body-ratio: 0.6;
  --header-main-avator-size: calc(72px * var(--body-ratio));
  --header-main-border-height: calc(40px * var(--body-ratio));
  --header-main-connector-size: calc(28px * var(--body-ratio));
  --header-main-bg-width: calc(62px * var(--body-ratio));
  --header-main-bg-height: calc(95px * var(--body-ratio));
  --header-vertical_reel-bg-width: calc(198px);
  --header-vertical_reel-bg-height: calc(57px);
  --header-vertical_reel-bg-bar-width: calc(241px);
  --header-vertical_reel-bg-bar-height: calc(27px);
  @include bg-column('header-vertical_reel', var(--header-vertical_reel-bg-width), var(--header-vertical_reel-bg-height), '../../assets-v4/20230706_main/mainpage_scrolldown_menu_bg_still2.png', '../../assets-v4/20230706_main/mainpage_scrolldown_menu_bg_still5.png', '../../assets-v4/20230706_main/mainpage_scrolldown_menu_bg_still3.png') {
    position: absolute;
    width: var(--header-vertical_reel-bg-bar-width);
    top: calc(var(--header-main-bg-height) + var(--header-top) + 10px);
    left: var(--header-left-right);
    align-items: center;
    opacity: 0;
    bottom: calc(100vh - var(--header-main-bg-height) - var(--header-top));
    height: calc(100vh - var(--header-height) - 80px);
    @keyframes header-vertical_reel {
      0% {
        opacity: 0;
        bottom: calc(100vh - var(--header-main-bg-height) - var(--header-top));
      }

      30% {
        opacity: 1;
      }

      100% {
        opacity: 1;
        bottom: 20px;
      }
    }
    animation-name: header-vertical_reel;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    // animation-timing-function: linear;
    // animation-iteration-count: 1;
    .header-vertical_reel-b,
    .header-vertical_reel-c,
    .header-vertical_reel-t {
      width: var(--header-vertical_reel-bg-width);
    }

    .header-vertical_reel-b,
    .header-vertical_reel-t {
      background-size: contain;
      height: calc(var(--header-vertical_reel-bg-height) - 1);
    }

    .header-vertical_reel-c {
      height: calc(100vh - var(--header-height) - 100px - 2 * var(--header-vertical_reel-bg-height));

      > div {
        @include scrollbar(8px, 6px);
      }

      .links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a,
        button,
        span {
          margin: 5px 0;
          display: block;
          width: 75%;
          // border-width: 2px;
          border-style: double;
          background-color: #FFF5EB;
          padding: 4px 8px;
          border-radius: 8px;
          box-sizing: content-box;
          text-align: left;
          text-decoration: none;
          pointer-events: all;
          $colors: #22B573, #22A1B2, #DD8E92, #FF9C6A, #962A94, #0B9B97, #BF6A6E, #4B6283;
          @for $i from 1 through length($colors) {
            &:nth-child(8n + #{$i}) {
              $c: nth($colors, $i);
              --color: #{$c};
              border-color: var(--color);
              color: var(--color);
            }
          }

          &.header-buttons {
            display: none;
            @include media-width-lte(1023px) {
              display: block;
            }
          }

          &.level-2 {
            margin-left: 10px;
            width: calc(75% - 10px);
            border-width: 2px;
            border-style: dotted;
            border-color: white;
            color: white;
            border-radius: 0;
            background-color: var(--color);
          }

          &.selected,
          &:hover {
            filter: drop-shadow(0px 0px 2px yellow);
          }
        }
      }
    }

    &:after,
    &:before {
      content: '';
      display: block;
      width: var(--header-vertical_reel-bg-bar-width);
      height: var(--header-vertical_reel-bg-bar-height);
      background-image: url("../../assets-v4/20230706_main/mainpage_scrolldown_menu_bg_still1.png");
      background-repeat: no-repeat;
      background-size: contain;
    }

    &-top_20 {
      top: calc(var(--header-main-bg-height) + var(--header-top) + 10px + calc(289px * var(--body-ratio) * .3) + 10px);
      height: calc(100vh - var(--header-height) - 80px - calc(289px * var(--body-ratio) * .3) - 10px);
      left: auto;
      right: var(--header-left-right);

      .header-vertical_reel-c {
        height: calc(100vh - var(--header-height) - 100px - 2 * var(--header-vertical_reel-bg-height) - calc(289px * var(--body-ratio) * .3) - 10px);
      }
    }
  }
}

header.v4 {
  position: absolute;
  top: var(--header-top);
  left: var(--header-left-right);
  right: var(--header-left-right);
  height: var(--header-height);
  user-select: none;
  z-index: 3000;
  // background: rgba(200,200,200,.4);
  display: flex;
  @include bg-toggle("header-quick-links-toggle", "../../assets-v4/main/mainpage_scrolldown_button.png", "../../assets-v4/main/mainpage_scrolldown_button_select.png") {
    width: var(--header-main-bg-height);
    height: var(--header-main-bg-height);
    min-width: 40px;
    min-height: 40px;
    margin-right: 1%;
  }

  .header-top_20 {
    position: absolute;
    right: 0;
    top: calc(10px + var(--header-main-bg-height));
    @include bg-row-abs('header-top_20-toggle', calc(218px * var(--body-ratio) * .3), calc(289px * var(--body-ratio) * .3), '../../assets-v4/20230710_treasure_cover/treasurepage_cover_red_button1.png', '../../assets-v4/20230710_treasure_cover/treasurepage_cover_red_button2.png', '../../assets-v4/20230710_treasure_cover/treasurepage_cover_red_button3.png') {
      width: calc(560px * var(--body-ratio));
      pointer-events: all;
      line-height: var(--header-main-bg-height);
      font-size: calc(var(--font-size-header) * .8);
      margin-bottom: 4px;
      font-weight: bold;
      text-align: center;

      .btn-c {
        color: #BD181F;
      }
    }
  }
  @include bg-row('header-main', var(--header-main-bg-width), var(--header-main-bg-height), '../../assets-v4/20230706_main/mainpage_menu-01.png', '../../assets-v4/20230706_main/mainpage_menu-02.png', '../../assets-v4/20230706_main/mainpage_menu-03.png') {
    flex: 1;

    .header-main-c {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1%;
      padding-left: 1%;
      padding-right: 1%;

      .header-main-avator {
        width: var(--header-main-avator-size);
        height: var(--header-main-avator-size);
        background: #B22F1C;
        border-radius: 50%;
        position: relative;

        &:before {
          content: '';
          display: block;
          padding: 5px;
          width: calc(100% - 12px);
          height: calc(100% - 12px);
          border: 1px solid #F5C7A1;
          background: #B12F1C;
          border-radius: 50%;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
        }
      }

      .header-main-name {
        width: calc(2.2 * var(--header-main-avator-size));
        height: var(--header-main-border-height);
        line-height: var(--header-main-border-height);
        color: #B22F1C;
        font-size: calc(var(--font-size-header) * .5);
        font-weight: bold;
        text-align: center;
        overflow: hidden;
        @include media-width-lte(1023px) {
          display: none;
        }
      }

      .header-main-class {
        width: calc(2.2 * var(--header-main-avator-size));
        font-weight: bold;
        @include header-main-border();
      }

      .header-main-score {
        width: calc(2.2 * var(--header-main-avator-size));
        font-weight: bold;
        @include header-main-border();

        &:after {
          content: '';
          width: calc(28px * var(--body-ratio));
          height: calc(28px * var(--body-ratio));
          background-image: url("../../assets-v4/20230706_main/mainpage_menu-04.png");
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 10%;
        }
      }

      .header-main-news {
        flex: 1;
        @include header-main-border();
        justify-content: flex-start;
        padding: 0 1%;
        font-weight: bold;
        overflow: hidden;

        &:before {
          content: '';
          width: calc(43px * var(--body-ratio));
          height: calc(29px * var(--body-ratio));
          background-image: url("../../assets-v4/20230706_main/mainpage_menu-05.png");
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 1%;
        }
        @keyframes news {
          0% {
            transform: translate(0, 0%);
          }

          100% {
            transform: translate(0, -100%);
          }
        }
        @keyframes news-2 {
          0% {
            transform: translate(0, 0%);
          }

          100% {
            transform: translate(0, -100%);
          }
        }

        .news {
          --line: 1;
          width: 100%;
          overflow: hidden;

          .news-marquee {
            animation-name: news;
            animation-duration: calc(var(--line) * 1s);
            animation-timing-function: linear;
            animation-iteration-count: infinite;

            div {
              &.news-1 {
                margin: 0;
              }

              &.news-2 {
                margin: 0;
              }

              a {
                color: black;
                text-decoration: none;
              }
            }

            &.news-marquee-1 {
              animation-name: news-2;
            }
          }
        }

        .news-button {
          position: absolute;
          display: none;
        }

        &-short {
          position: relative;

          &:before {}

          .news {
            opacity: 0;
          }

          .news-button {
            display: flex;
            left: calc(43px * var(--body-ratio));
            right: 0;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .header-buttons {
    display: flex;
    @include bg-row('header-buttons-button', var(--header-main-bg-width), var(--header-main-bg-height), '../../assets-v4/20230706_main/mainpage_menu-01.png', '../../assets-v4/20230706_main/mainpage_menu-02.png', '../../assets-v4/20230706_main/mainpage_menu-03.png') {
      width: calc(2.8 * var(--header-main-bg-width));
      position: relative;
      padding: 0;

      &:before {
        content: '';
        width: var(--header-main-connector-size);
        height: var(--header-main-connector-size);
        background-image: url("../../assets-v4/20230706_main/mainpage_menu-06.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: calc(-.5* var(--header-main-connector-size));
        top: calc(50% - var(--header-main-connector-size) / 2);
      }

      .header-buttons-button-icon {
        position: absolute;
        top: 12%;
        left: 12%;
        right: 12%;
        bottom: 12%;
      }

      &.header-buttons-button-info {
        @include bg-toggle('header-buttons-button-icon', "../../assets-v4/main/mainpage_question_button.png", "../../assets-v4/main/mainpage_question_button_select.png")
      }

      &.header-buttons-button-rank {
        @include bg-toggle('header-buttons-button-icon', "../../assets-v4/main/mainpage_award_button.png", "../../assets-v4/main/mainpage_award_button_select.png")
      }

      &.header-buttons-button-settings {
        @include bg-toggle('header-buttons-button-icon', "../../assets-v4/main/mainpage_setting_button.png", "../../assets-v4/main/mainpage_setting_button_select.png")
      }

      &.header-buttons-button-bg-music {
        @include bg-toggle('header-buttons-button-icon', "../../assets-v4/main/mainpage_closemusic_button.png", "../../assets-v4/main/mainpage_closemusic_button_select.png")
      }

      &.header-buttons-button-bg-music-on {
        @include bg-toggle('header-buttons-button-icon', "../../assets-v4/main/mainpage_music_button.png", "../../assets-v4/main/mainpage_music_button_select.png")
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    .header-buttons {
      display: none;
    }
  }

  .modal-profile-body-info-name-l {
    width: var(--size-title-lr-width);
    height: 100%;
    background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting5.png");
  }

  .modal-profile-body-info-name-c {
    flex: 1;
    background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting6.png");
  }

  .modal-profile-body-info-name-r {
    width: var(--size-title-lr-width);
    height: 100%;
    background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting22.png");
  }
}
