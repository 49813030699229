.aspect-box {
  --x: 1;
  --y: 1;
  // position: relative;
  // overflow: hidden;
  // height: 0;

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(var(--x) / var(--y) * 100%);
  }

  &::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
  }

  .aspect-box-16-9 {
    --x: 16;
    --y: 9;
  }
}
