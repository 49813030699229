.page-settings-main {
  .volume {
    position: absolute;
    top: 32%;
    width: 70%;
    left: 15%;
    height: 15%;
    color: #ffc85a;
    font-size: calc(var(--app-width) * .022 * var(--zoom));
    font-weight: bold;
    // display: block;
    display: flex;
    flex-direction: row;

    .label {
      margin-top: 1.2em;
      margin-right: 2em;
    }

    .volume-dragbar {
      flex: 5;
      width: 100%;
      display: flex;
      flex-direction: row;
      user-select: none;

      .volume-dragbar-left,
      .volume-dragbar-right {
        display: flex;
        flex: 1;
      }

      .volume-dragbar-bar {
        flex: 6;
        display: flex;
        position: relative;
        flex-direction: column;

        .volume-dragbar-bar-scrollbar-wrapper {
          background-repeat: no-repeat;
          background-color: none;
          background-position: center;
          overflow: hidden;
        }

        .volume-dragbar-bar-scrollbar-track-x {
          width: 100%;
          height: 20% !important;
          display: block;
          position: absolute;
          background: transparent !important;
          top: 36%;
        }

        .volume-dragbar-bar-scrollbar-thumb-x {
          // aspect-ratio: 160 / 24;
          // width: calc(var(--app-width) * .51 * var(--zoom));
          // height: calc(var(--app-height) * .1 * var(--zoom));
          &::before {
            content: "";
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: calc(24 / 160 * 100%);
          }

          &::after {
            /* to clear float */
            content: "";
            display: table;
            clear: both;
          }
          display: block;
          // position: absolute;
          // right: 0;
          background-repeat: no-repeat;
        }
        // img {
        //   top: 1.2em;
        //   position: absolute;
        //   left: 0;
        //   right: 0;
        // }
        .volume-dragbar-bar-bg {}

        .volume-dragbar-bar-drag {
          top: 1.4em;
          width: 22%;
        }
      }
    }
  }

  .buttons {
    position: absolute;
    top: 60%;
    width: 70%;
    left: 15%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a,
    button {
      display: flex;
      border: none;
      background: transparent;
      width: 100%;
      flex: 1;

      img {
        width: 100%;
      }
    }
  }
}
