.dialog-teacher {
  width: 320px;
  height: 220px;
  background: #009900;
  color: white;
  padding: 10px;
  z-index: 10000;
  position: absolute;
  left: 10px;
  top: 10px;

  h3 {
    margin: 0 0 5px;
    font-size: 24px;
  }

  .dialog-teacher-filter {
    display: flex;
    margin: 0 0 5px;

    label {
      flex: 1;
    }

    .dialog-teacher-filter-field {
      flex: 3;

      input,
      select {
        font-size: 18px;
        // width: …100%;
      }
    }
  }

  .dialog-btn-reset {
    width: 80px;
    height: 48px;
    background: #fde258;
    border-radius: 20px;
    border: 5px solid #fdb304;
    font-size: 22px;
    font-weight: bold;
  }

  .dialog-btn-download {
    width: 180px;
    height: 48px;
    background: #fde258;
    border-radius: 20px;
    border: 5px solid #fdb304;
    font-size: 22px;
    font-weight: bold;

    &[disabled] {
      filter: grayscale(1);
    }
  }
}
