.page-leaderboard {
  .leaderboard-area {
    position: absolute;
    top: 18%;
    left: 27%;
    width: 68%;
    height: 64%;
    background-repeat: no-repeat;
    background-position: top right;

    .leaderboard-area-scrollbar-wrapper {
      height: 100%;
    }

    .leaderboard-area-scrollbar-track-y {
      height: 90%;
      width: 4% !important;
      display: block;
      position: absolute;
      right: 0.2em !important;
      // background-repeat: no-repeat;
      background: transparent !important;
    }

    .leaderboard-area-scrollbar-thumb-y {
      width: 66%;
      display: block;
      position: absolute;
      right: -6%;
      background-repeat: no-repeat;
    }

    .leaderboard-area-inner {
      width: 95%;
      // height: 100%;
      // overflow-y: hidden;
      .leaderboard-user-item {
        width: 100%;
        // aspect-ratio: 18 / 2;
        width: calc(var(--app-width) * .51 * var(--zoom));
        height: calc(var(--app-height) * .1 * var(--zoom));
        padding-bottom: 1%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: row;
        color: white;
        align-items: center;

        .leaderboard-user-item-icon {
          flex: 1;
          height: 80%;
          margin-left: 5%;
          background-repeat: no-repeat;
        }

        .leaderboard-user-item-name {
          font-size: 150%;
          flex: 5;
        }

        .leaderboard-user-item-mark {
          font-size: 150%;
          flex: 2;
          // text-align: center;
          padding-left: 5%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  @import './dialog-teacher';
}
