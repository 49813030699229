body.v4 {
  .modal-profile-avator_selector {
    .image-grid {
      display: grid;
      grid-template-columns: repeat(8, 12.5%);

      img {
        width: 100%;

        &:hover,
        &.selected {
          filter: drop-shadow(0px 0px 8px yellow);
        }

        &.disabled {
          filter: grayscale(.8);
          pointer-events: none;
        }

        &.disabled:hover {
          filter: grayscale(.8);
        }
      }
    }
  }
}
