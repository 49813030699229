header.v1 {
  position: absolute;
  top: var(--header-top);
  left: 0;
  right: 0;
  height: var(--header-height);
  user-select: none;
  z-index: 3000;

  .student-bar-type {
    position: absolute;
    width: 175px;
    height: 45px;
    top: 14px;
    left: 133px;
    background-color: #f3ca64;

    .student-bar-type-text {
      --arena-color-1: #CE0A00;
      position: relative;
      font-size: 40px;
      font-weight: bold;

      .student-bar-type-text-bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 8px;
        left: 4px;
        color: var(--arena-color-1);
        filter: brightness(.9);
      }

      .student-bar-type-text-text {
        --border: 2px;
        --border-minus: -2px;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 8px;
        left: 4px;
        color: white;
        text-shadow: var(--border-minus) var(--border-minus) 0 var(--arena-color-1), 0 var(--border-minus) 0 var(--arena-color-1), var(--border) var(--border-minus) 0 var(--arena-color-1), var(--border) 0 0 var(--arena-color-1), var(--border) var(--border) 0 var(--arena-color-1), 0 var(--border) 0 var(--arena-color-1), var(--border-minus) var(--border) 0 var(--arena-color-1), var(--border-minus) 0 0 var(--arena-color-1);
      }
    }
  }

  .student-bar {
    position: absolute;
    // top: 10%;
    left: calc(1.5 * var(--header-left-right));
    width: 74.2%;
    font-size: calc(var(--app-width) * .015 * var(--zoom));
    font-weight: bold;

    .student-bar-main-bar {
      position: relative;
      background-repeat: no-repeat;
      width: 1420px;
      height: 98px;
    }

    .student-bar-profile {
      position: absolute;
      top: 0;
      width: 7%;
      left: 1.5%;
      z-index: 30;
      height: 100%;
      display: block;

      button {
        border: none;
        background: transparent;
        width: 154px;
        height: 150px;
        transform: scale(1.15) translateY(-3px);

        &.student-bar-profile-my-profile {
          // width: 120px;
          // height: 120px;
          // transform: scale(1);
          transform: scale(.8) translate(-37px, -38px);
        }

        img {}
      }
    }

    .class {
      position: absolute;
      top: 20%;
      left: 23%;
    }

    .star {
      position: absolute;
      top: 20%;
      left: 37.5%;
      display: flex;

      img {
        padding-right: 20%;
      }
    }
    @keyframes news {
      0% {
        transform: translate(0, 0%);
      }

      100% {
        transform: translate(0, -100%);
      }
    }
    @keyframes news-2 {
      0% {
        transform: translate(0, 0%);
      }

      100% {
        transform: translate(0, -100%);
      }
    }

    .news {
      --line: 1;
      position: absolute;
      top: 20%;
      left: 54%;
      line-height: calc(1.4 * var(--zoom));
      overflow: hidden;
      height: 40%;
      width: 40%;

      .news-marquee {
        animation-name: news;
        animation-duration: calc(var(--line) * 1s);
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        div {
          // height: 80px;
          &.news-1 {
            margin: 0;
            // animation: marquee1 calc(var(--line) * var(--header-marquee-duration)) linear infinite;
          }

          &.news-2 {
            margin: 0;
            // animation: marquee2 calc(var(--line) * var(--header-marquee-duration)) linear infinite;
          }

          a {
            color: black;
            text-decoration: none;
          }
        }

        &.news-marquee-1 {
          animation-name: news-2 ;
        }
      }
      // @keyframes marquee1 {
      //   0% {
      //     transform: translate(0, 0%);
      //   }
      //
      //   50% {
      //     transform: translate(0, -100%);
      //     display: none;
      //   }
      //
      //   50.01% {
      //     transform: translate(0, 100%);
      //     display: none;
      //   }
      //
      //   100% {
      //     transform: translate(0, 0%);
      //     display: block;
      //   }
      // }
      // @keyframes marquee2 {
      //   0% {
      //     transform: translate(0, 0%);
      //   }
      //
      //   50% {
      //     transform: translate(0, -100%);
      //   }
      //
      //   100% {
      //     transform: translate(0, -200%);
      //   }
      // }
    }
  }

  .buttons {
    position: absolute;
    right: var(--header-left-right);
    width: 20%;
    height: 100%;
    text-align: right;

    a,
    button,
    span {
      width: 30%;
      cursor: url('../../assets/png_icon/mouse.cur'), auto;
      // aspect-ratio: 1;
      // height: 100%;
      border: 0;
      background: none;
      padding: 1% 3%;
      display: inline-block;
      // <button/>
      &:focus {
        border: 0;
      }

      img,
      svg {
        &:hover {
          filter: brightness(.7);
        }
      }
    }
  }
}
