.scrollbar-vertical-wrapper {
  height: 100%;
}

.scrollbar-vertical-track-y {
  height: 90%;
  // width: 4% !important;
  display: block;
  position: absolute;
  // right: 0.2em !important;
  // background-repeat: no-repeat;
  background: transparent !important;
}

.scrollbar-vertical-thumb-y {
  // width: 66%;
  display: block;
  position: absolute;
  // right: -6%;
  background-repeat: no-repeat;
  // background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><linearGradient id='gradient'><stop offset='10%' stop-color='%23F00'/><stop offset='90%' stop-color='%23fcc'/> </linearGradient><rect fill='url(%23gradient)' x='0' y='0' width='100%' height='100%'/></svg>");

  // height: 20vh !important;
  // aspect-ratio: 23 / 150;
}

.scrollbar-vertical-inner {
  width: 95%;
  height: 100%;
  overflow-y: hidden;
}

// .simplebar-track {
//   &.simplebar-vertical {
//     aspect-ratio: 11 / 150;
//     background: rgba(99,99,99,.5);
//   }
//
//   .simplebar-scrollbar {
//     aspect-ratio: 23 / 150;
//     background: rgba(99,0,0,.5);
//     &.simplebar-visible {}
//   }
// }
