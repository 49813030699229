@import "./scss/utils/bg-row";
@import "./scss/utils/animation";
@import "./scss/utils/media-ar";

#root {
  //
  --v4-width: 1;
  --v4-height: 1;
  @media (min-aspect-ratio: 1/1), (aspect-ratio: 1/1) {
    --ar-size-ratio: calc(1vw / 1920);
    --font-size-header: calc(3vw);
    --font-size-base: calc(2vw);
    @media (min-aspect-ratio: 16/9) {
      --font-size-header: calc(5vh);
      --font-size-base: calc(4vh);
    }
  }
  // ar < 1/1
  @media (max-aspect-ratio: 1/1) {
    --ar-size-ratio: calc(1vh / 1080);
    --font-size-header: calc(3vh);
    --font-size-base: calc(2.5vh);
  }
}
@import "./scss/v4/_scrollbar";
@import "animate.css/animate.min.css";

body.v4 {
  background: none;
}
@media only screen and (max-height: 719px), screen and (max-width: 1023px) {
  body.v4 {
    background-color: rgba(99,0,0,.4);
  }
}

#root.v4 {
  width: 100vw;
  height: calc(100vh - (var(--v4-ios) * 50px));
  overflow: hidden;
  background-color: rgba(0,99,0,.4);
}

.AppV4 {
  display: flex;
  width: 100vw;
  height: calc(100vh - (var(--v4-ios) * 50px));
  background-repeat: repeat-x;
  background-size: cover;
  position: relative;

  button {
    border: none;
    background: none;
  }

  .tg-bg {}

  .tg-page {
    flex: 1;
    display: flex;

    .page {
      // background: aqua;
      width: 100%;
    }
  }

  .modal-container {
    z-index: 5000;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.modal-container-no-bg {
      background-color: transparent;
      pointer-events: none;
    }
  }

  .page {
    a.btn {
      position: absolute;
      right: 20px;
      top: 20px;
      text-decoration: none;
      z-index: 3;
    }
    @include bg-row-abs('btn-back_to_main', calc(218px * var(--body-ratio) * .3), calc(289px * var(--body-ratio) * .3), '../../assets-v4/20230710_treasure_cover/treasurepage_cover_red_button1.png', '../../assets-v4/20230710_treasure_cover/treasurepage_cover_red_button2.png', '../../assets-v4/20230710_treasure_cover/treasurepage_cover_red_button3.png') {
      // width: calc(460px * var(--body-ratio));
      width: calc(var(--font-size-header) * 6);
      pointer-events: all;
      line-height: var(--header-main-bg-height);
      font-size: calc(var(--font-size-header) * .8);
      @include media-width-between(1270px, 1320px) {
        font-size: 32px;
      }
      margin-bottom: 4px;
      font-weight: bold;text-align: center;

      .btn-c {
        color: #BD181F;
      }

    }
    .btn-back_to_main:hover {
      @include animationCss(pulse, 1s);
    }
  }
}

.dev-css-mq {
  position: absolute;
  top: 1px;
  right: 1px;
  z-index: 6000;

  div {
    width: 14px;
    height: 14px;
    color: white;
    text-align: center;
    font-size: 12px;

    &.dev-css-mq-16-9 {
      @media (min-aspect-ratio: 16/9), (aspect-ratio: 16/9) {
        background: red;
      }
      @media (max-aspect-ratio: 16/9) {
        background: blue;
      }
    }

    &.dev-css-mq-5-4 {
      @media (min-aspect-ratio: 5/4), (aspect-ratio: 5/4) {
        background: red;
      }
      @media (max-aspect-ratio: 5/4) {
        background: blue;
      }
    }

    &.dev-css-mq-3-2 {
      @media (min-aspect-ratio: 3/2), (aspect-ratio: 3/2) {
        background: red;
      }
      @media (max-aspect-ratio: 3/2) {
        background: blue;
      }
    }

    &.dev-css-mq-1-1 {
      @media (min-aspect-ratio: 1/1), (aspect-ratio: 1/1) {
        background: red;
      }
      @media (max-aspect-ratio: 1/1) {
        background: blue;
      }
    }
  }
}
