body.v4 {
  .modal-about_us {
    .modal-title {
      position: absolute;
      width: calc(var(--size-title-width) * 1.5);
      height: var(--size-title-height);
      left: calc(50% - var(--size-title-width) / 2);
      top: 8%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;

      .modal-title-l {
        width: var(--size-title-lr-width);
        height: 100%;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting5.png");
        background-repeat: no-repeat;
      }

      .modal-title-c {
        flex: 1;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting6.png");
        background-repeat: repeat-x;
        margin: 0 -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-header);
      }

      .modal-title-r {
        width: var(--size-title-lr-width);
        height: 100%;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting22.png");
        background-repeat: no-repeat;
      }
    }

    .modal-about_us-body {
      // background: rgba(100,100,100,.5);
      width: 100%;
      height:  calc(100% - var(--size-title-height));
      display: flex;
      // gap: calc(var(--ar-size-ratio) * 10000);
      font-size: 20px;
      margin-top: var(--size-title-height);
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
