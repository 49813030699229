.resizable-text-big {
  .page-island-trivia-content {
    font-size: 36px;
  }

  .page-island-qna-pagination {
    font-size: 36px;
  }

  .page-island-qna-title-text {
    font-size: 38px;
  }

  .page-island-qna-answer span {
    font-size: 36px;
  }

  .page-island-qna-fill-answer span {
    font-size: 36px;
    bottom: -8px;
  }

  .page-island-qna-sort-answer span {
    font-size: 36px;
  }

  .page-island-qna-match-answer span {
    font-size: 36px;
  }
}

.resizable-text-normal {
  .page-island-trivia-content {
    font-size: 26px;
  }

  .page-island-qna-pagination {
    font-size: 26px;
  }

  .page-island-qna-title-text {
    font-size: 28px;
  }

  .page-island-qna-answer span {
    font-size: 26px;
  }

  .page-island-qna-fill-answer span {
    font-size: 26px;
    bottom: -6px;
  }

  .page-island-qna-sort-answer span {
    font-size: 26px;
  }

  .page-island-qna-match-answer span {
    font-size: 26px;

    &.text-small {
      font-size: 22px;
    }
  }
}

.resizable-text-small {
  .page-island-trivia-content {
    font-size: 16px;
  }

  .page-island-qna-pagination {
    font-size: 16px;
  }

  .page-island-qna-title-text {
    font-size: 18px;
  }

  .page-island-qna-answer span {
    font-size: 16px;
  }

  .page-island-qna-fill-answer span {
    font-size: 16px;
    bottom: -4px;
  }

  .page-island-qna-sort-answer span {
    font-size: 16px;
  }

  .page-island-qna-match-answer span {
    font-size: 16px;
  }
}

.page-arena,
.page-island {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .btn-back,
  a {
    position: absolute;
    top: 0;
  }

  .btn-back {
    top: 14%;
    right: 3%;
    width: 9%;

    &.btn-back-homepage {
      width: 12%;
    }
  }

  > div {
    width: 100%;
    height: 100%;
  }

  .page-island-main {
    .page-island-main-button {
      &.disabled {
        filter: grayscale(1);
        pointer-events: none;
      }
    }

    .page-island-main-star {
      // aspect-ratio: 6;
      position: absolute;
      top: var(--top);
      left: var(--left);
      width: 100%;
      display: flex;
      justify-content: center;

      div {
        width: calc(var(--app-width) * .025 * var(--zoom));
        height: calc(var(--app-width) * .025 * var(--zoom));
        // aspect-ratio: 1 / 1;
        background-image: var(--bg-off);
        padding: 0 1%;
        background-repeat: no-repeat;

        &.page-island-main-star-on {
          background-image: var(--bg);
        }
      }
    }

    .page-island-main-bar-wrapper {
      position: absolute;
      left: 28.4%;
      top: 83.6%;
      width: 43.9%;
      height: 6%;

      .page-island-main-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .page-island-qna {
    // font-size: calc(var(--app-width) * .014 * var(--zoom));
    .page-island-qna-pagination {
      position: absolute;
      top: 25%;
      width: 55%;
      left: 18%;
      text-align: center;
    }

    .page-island-qna-title {
      position: absolute;
      top: 30%;
      width: 55%;
      left: 18%;
      height: 240px;
    }

    .page-island-qna-question {
      position: absolute;
      top: 34%;
      bottom: 47%;
      width: 55%;
      left: 18%;
    }

    .page-island-qna-btn-img-popup-wrapper {
      top: 54%;
      left: 20%;
      width: 160px;
      height: 130px;
      border: 2px solid #F00;
      border-radius: 5px;
      position: absolute;

      .page-island-qna-btn-img-popup {
        // position: absolute;
        background: transparent;
        // top: 54%;
        // left: 20%;
        border: none;
        width: 100%;
        // height: 91px;
        img {
          width: 113px;
          height: 91px;
          margin-top: 5px;
          margin-bottom: 5px;
        }

        div {}
      }
    }

    .page-island-qna-answer {
      position: absolute;
      top: 54%;
      bottom: 24%;
      width: 43%;
      left: 25%;
      display: grid;
      grid-template-columns: 1fr 1fr;

      button {
        border: none;
        background: none;
        background-size: cover;
        background-repeat: no-repeat;
        // width: 40%;
        // aspect-ratio: 300 / 107;
        width: calc(var(--app-width) * .2 * var(--zoom));
        height: calc(var(--app-width) * .071 * var(--zoom));
        border-radius: 9%;
        margin: 1%;

        span {
          color: white;
          // font-size: calc(var(--app-width) * .018 * var(--zoom));
          &.correct {
            color: black;
          }
        }

        &.selected {
          box-shadow: 0 0 calc(var(--app-width) * .005 * var(--zoom)) calc(var(--app-width) * .002 * var(--zoom)) #FF0;
        }
      }
    }

    .page-island-qna-fill-answer-title-ans {
      display: inline-block;
      width: 10%;
      text-align: center;
      color: blue;
      border-bottom: 1px solid blue;
      position: relative;

      span {
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        text-align: center;
      }

      &.page-island-qna-fill-answer-title-ans-selected {
        color: green;
        border-bottom: 1px solid green;

        span {
          font-size: calc(var(--app-width) * .018 * var(--zoom));
        }
      }
    }

    .page-island-qna-fill-title {
      height: 180px;
    }

    .page-island-qna-fill-answer {
      position: absolute;
      top: 48%;
      bottom: 24%;
      width: 44%;
      left: 24%;

      .page-island-qna-fill-answer-hints {
        color: red;
        font-size: calc(var(--app-width) * .010 * var(--zoom));
        padding-left: 3%;
        padding-bottom: 2%;
      }

      .page-island-qna-fill-answer-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: space-around;
        padding-bottom: 1%;

        button {
          border: none;
          background: none;
          background-repeat: no-repeat;
          width: 45%;
          // aspect-ratio: 382 / 109;
          width: calc(var(--app-width) * .2 * var(--zoom));
          height: calc(var(--app-width) * .057 * var(--zoom));
          border-radius: 9%;

          span {
            color: white;
            // font-size: calc(var(--app-width) * .018 * var(--zoom));
          }

          &.selected {
            box-shadow: 0 0 calc(var(--app-width) * .005 * var(--zoom)) calc(var(--app-width) * .002 * var(--zoom)) #FF0;
          }
        }
      }
    }

    .page-island-qna-sort-title {
      height: 100px;
    }

    .page-island-qna-sort-answer {
      position: absolute;
      // background: red;
      top: 40%;
      bottom: 24%;
      width: 40%;
      left: 26%;
      user-select: none;
      color: white;

      .page-island-qna-sort-answer-hints {
        color: red;
        font-size: calc(var(--app-width) * .01 * var(--zoom));
        padding-left: 3%;
        padding-bottom: 2%;
      }

      .page-island-qna-sort-answer-ans {
        background-repeat: no-repeat;
        position: absolute;
        right: 2%;
        width: 3%;
        // aspect-ratio: 1;
        // width: calc(var(--app-width) * .05 * var(--zoom));
        // height: calc(var(--app-width) * .05 * var(--zoom));
        top: 0;
        display: none;
      }

      .page-island-qna-sort-answer-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: space-around;
        padding-bottom: 1%;

        > div {
          background-repeat: no-repeat;
        }

        .page-island-qna-sort-answer-row-select {
          border: none;
          background-color: transparent;
          flex: 1;
          // aspect-ratio: 1/1;
          width: calc(var(--app-width) * .03 * var(--zoom));
          height: calc(var(--app-width) * .032 * var(--zoom));
          text-align: center;
          position: relative;
          margin-right: 2%;
          // padding-left: 0.5%;
          // height: 100%;
          span {
            font-weight: bold;
            line-height: 55px;
            margin-left: -3px;
          }

          .page-island-qna-sort-answer-row-select-list {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            // aspect-ratio: 65 / 232;
            z-index: 3;
            display: flex;
            flex-direction: column;
            // background-color: #F00;
            background-repeat: no-repeat;
            background-color: #C26C14;
            border-radius: calc(var(--app-width) * .005 * var(--zoom));
            width: 60px;

            &.page-island-qna-sort-answer-row-select-list-up {
              top: auto;
              bottom: 0;
            }

            .page-island-qna-sort-answer-row-select-list-spacer {
              // aspect-ratio: 3 / 2;
              width: calc(var(--app-width) * .031 * var(--zoom));
              height: calc(var(--app-width) * .020 * var(--zoom));
            }

            .page-island-qna-sort-answer-row-select-list-options {
              // background: red;
              width: calc(var(--app-width) * .029 * var(--zoom));
              height: calc(var(--app-width) * .024 * var(--zoom));
              // aspect-ratio: 20 / 19;
              padding: 10% 4%;

              &:last-child {
                padding-bottom: 20%;
              }

              span {
                background-color: #F4901E;
                border-radius: calc(var(--app-width) * .005 * var(--zoom));
                padding-top: 10%;
                display: inline-block;
                width: 100%;
                height: 100%;
                line-height: 44px;
                margin-left: 0;
              }
            }
          }
        }

        .page-island-qna-sort-answer-row-text {
          flex: 11;
          // background-position: center;
          // margin-left: 3%;
          // height: 100%;
          span {
            padding-left: 8%;
            line-height: 55px;
          }
        }

        .page-island-qna-sort-answer-row-ans {
          color: black;
          flex: 1;
          // aspect-ratio: 1/1;
          text-align: center;
          // padding-left: 2%;
          // margin-bottom: 1%;
          background-position: right;

          span {
            font-weight: bold;
            line-height: 55px;
            padding-left: 10px;
          }
        }
      }
    }

    .page-island-qna-match-title {
      height: 200px;
    }

    .page-island-qna-match-answer {
      position: absolute;
      top: 52%;
      bottom: 24%;
      width: 54%;
      left: 19%;
      user-select: none;

      .page-island-qna-match-answer-row {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: space-around;
        padding-bottom: 2%;

        .page-island-qna-match-answer-row-option-left,
        .page-island-qna-match-answer-row-option-right {
          color: white;
          text-align: center;
          background-repeat: no-repeat;
          width: 35%;
          width: calc(var(--app-width) * .218 * var(--zoom));
          height: calc(var(--app-width) * .0335 * var(--zoom));
          background-size: contain;
          // aspect-ratio: 390 / 68;
          position: relative;
          background-position: right;

          .page-island-qna-match-answer-row-option-bullet {
            pointer-events: none;
            position: absolute;
            background-repeat: no-repeat;
            // aspect-ratio: 1 / 1;
            width: calc(var(--app-width) * .008 * var(--zoom));
            height: calc(var(--app-width) * .008 * var(--zoom));
            // width: 5%;
            top: 50%;
            transform-origin: center;
            transform: translateY(-50%);
            left: 0;

            &.active {
              // width: 7%;
              // left: -9%;
              // transform: scale(1.2);
            }
          }

          .page-island-qna-match-answer-row-option-text-wrapper {
            pointer-events: none;
            user-select: none;
            line-height: 55px;
            // display: inline-block;
            width: 85%;
            height: 55px;
            margin: 5px 0 5px 55px;
            overflow: hidden;
            // width: 550px;
            // height: 105px;
            // position: absolute;
            // top: 25px;
            // left: 150px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-content: center;

            > div {
              height: 100%;
              align-items: center;
              display: flex;
              text-align: center;
              justify-content: center;
              align-content: center;

              span {
                line-height: 26px;
                // display: inline-block;
                // width: 85%;
                // height: 50%;
                overflow: hidden;
                // background-color: red;
              }
            }
          }
        }

        .page-island-qna-match-answer-row-option-left {
          background-position: left;

          .page-island-qna-match-answer-row-option-bullet {
            right: 0;
            left: auto;

            &.active {
              // width: 7%;
              left: auto;
              right: 0;
            }
          }

          .page-island-qna-match-answer-row-option-text-wrapper {
            margin: 5px 55px 0 0;

            > div {
              span {}
            }
          }
        }
      }
    }

    .page-island-qna-submit {
      position: absolute;
      border: none;
      background: none;
      width: 10%;
      top: 86%;
      left: 41%;

      .page-island-qna-submit-spin {
        position: absolute;
        top: 15px;
        left: 72px;
        display: none;
      }

      &.disabled {
        filter: grayscale(1);

        .page-island-qna-submit-spin {
          display: block;
        }
      }
    }
  }

  .page-island-animation {
    img, video {
      position: absolute;
      top: 21%;
      width: 1280px;
      height: 720px;
      left: 15.5%;

      &.enlarged {
        transform-origin: center;
        transform: scale(1.45);
        z-index: 3000;
        top: 17%;
        left: 16.5%;
      }
    }

    .btn-enlarge {
      border: none;
      background: transparent;
      width: 150px;
      height: 150px;
      position: absolute;
      right: 54px;
      top: 230px;
    }

    .btn-normal {
      border: none;
      background: white;
      width: 100px;
      height: 100px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 5000;
    }
  }

  .page-island-game {
    iframe {
      position: absolute;
      top: 230px;
      width: 1280px;
      height: 720px;
      left: 300px;
      background: #234585;

      &.enlarged {
        transform-origin: center;
        transform: scale(1.45);
        z-index: 3000;
        top: 17%;
        left: 16.5%;
      }
    }

    .btn-enlarge {
      border: none;
      background: transparent;
      width: 150px;
      height: 150px;
      position: absolute;
      right: 54px;
      top: 230px;
    }

    .btn-normal {
      border: none;
      background: white;
      width: 100px;
      height: 100px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 5000;
    }
  }

  .page-island-trivia {
    font-family: roboto, sans-serif;

    .page-island-trivia-pagination {
      position: absolute;
      right: 20%;
      top: 27%;
      font-size: calc(var(--app-width) * .02 * var(--zoom));
    }

    .page-island-trivia-btn-next {
      width: 3%;
      right: 15%;
      top: 27%;
      display: inline-block;
      position: absolute;
      background: none;
      border: none;
    }

    div.page-island-trivia-content {
      position: absolute;
      top: 35%;
      bottom: 20%;
      width: 55%;
      left: 30%;
      // font-size: calc(var(--app-width) * .02 * var(--zoom));
    }
  }

  .page-island-cms-preview-mode {
    position: absolute;
    background: rgba(0,224,0,.7);
    color: white;
    font-weight: bold;
    bottom: 60px;
    right: 360px;
    width: 360px;
    height: 200px;
    padding: 10px;
    overflow-y: scroll;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    h4 {
      font-size: 32px;
      margin-top: 0;
      margin-bottom: 4px;
    }

    v li {
      font-size: 18px;
    }
  }
}

div.dialog.page-island-qna-score {
  .dialog-content {
    top: 33%;
    height: 55%;

    .page-island-qna-score-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      > div {
        justify-content: center;
        display: flex;

        &.ans {
          font-size: 36px;
          padding-top: 20px;
        }

        &.stars {
          justify-content: space-between;
          width: 50%;
          margin: 0 auto;
          height: 40%;
          padding: 3% 0;

          img {
            width: 200px;
          }
        }

        &.msg {
          font-size: 36px;
        }
      }
    }
  }
}

div.dialog.page-island-qna-img-popup {
  .dialog-btn-close {
    top: 5%;
  }

  .page-island-qna-img-popup-btn-download {
    position: absolute;
    top: 5%;
    left: 5%;
  }

  .dialog-content {
    top: 6%;
    height: 76%;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}
