body.v4 {
  .modal-technical_support {
    .modal-title {
      position: absolute;
      width: calc(var(--size-title-width) * 1.5);
      height: var(--size-title-height);
      left: calc(50% - var(--size-title-width) / 2);
      top: 8%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;

      .modal-title-l {
        width: var(--size-title-lr-width);
        height: 100%;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting5.png");
        background-repeat: no-repeat;
      }

      .modal-title-c {
        flex: 1;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting6.png");
        background-repeat: repeat-x;
        margin: 0 -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-header);
      }

      .modal-title-r {
        width: var(--size-title-lr-width);
        height: 100%;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting22.png");
        background-repeat: no-repeat;
      }
    }

    .modal-technical_support-body {
      // background: rgba(100,100,100,.5);
      width: 100%;
      height: calc(100% - var(--size-title-height) * 1.2);
      display: flex;
      // gap: calc(var(--ar-size-ratio) * 10000);
      font-size: 20px;
      margin-top: var(--size-title-height);
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .form {
        .input-group {
          .input-label {
            font-size: 20px;
          }

          .input-field {
            height: 48px;
            display: flex;

            &.input-field-textarea {
              height: 220px;
            }

            &.input-field-select {
              button {
                // right: 20%;
              }
            }

            .input-field-select-options {
              font-size: 20px;
              border-radius: 5px;
              border: 2px solid #4A79A9;
              background: white;
              height: auto;

              .input-field-select-option {
                &:active,
                &:hover {
                  background: lightgray;
                }
              }
            }
          }

          input,
          textarea {
            border-radius: 5px;
            border: 2px solid #4A79A9;
            height: 30px;
            width: 95%;
            margin: 0;
            padding: 3px;
            font-size: 20px;
          }

          textarea {
            height: 200px;
          }
        }
      }

      .button-area {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .page-settings-technical-support-submit {
          border-radius: 5px;
          border: 2px solid #4A79A9;
          height: 48px;
          font-size: 32px;
          background: white;
          width: 100px;

          &[disabled] {
            filter: grayscale(1);
          }
        }
      }
    }
  }
}
