body.v4 {
  .modal-settings {
    .modal-settings-dingding-1 {
      position: absolute;
      top: var(--size);
      height: 50vh;
      width: calc(50vh / 2502 * 1497);
      left: calc(50vh / 2502 * 1497 / -3);
      background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting4.png");
      z-index: 2;
    }

    .modal-title {
      position: absolute;
      width: var(--size-title-width);
      height: var(--size-title-height);
      left: calc(50% - var(--size-title-width) / 2);
      top: 12%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;

      .modal-title-l {
        width: var(--size-title-lr-width);
        height: 100%;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting5.png");
        background-repeat: no-repeat;
      }

      .modal-title-c {
        flex: 1;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting6.png");
        background-repeat: repeat-x;
        margin: 0 -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-header);
      }

      .modal-title-r {
        width: var(--size-title-lr-width);
        height: 100%;
        background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting22.png");
        background-repeat: no-repeat;
      }
    }

    .modal-settings-body {
      // background: rgba(100,100,100,.5);
      width: 100%;
      height: 100%;
      display: flex;
      gap: calc(var(--ar-size-ratio) * 10000);
      margin-top: calc(var(--ar-size-ratio) * 10000);
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .modal-settings-body-volume {
        display: flex;
        gap: calc(var(--ar-size-ratio) * 1000);
        flex-direction: row;
        width: 80%;
        justify-content: center;
        align-items: center;

        .modal-settings-body-volume-minus {
          width: calc(var(--size-button-height) / 5 * 3);
          height: calc(var(--size-button-height) / 5 * 3);
          background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting9.png");
        }

        .modal-settings-body-volume-value {
          display: none;
          font-weight: bold;
          font-size: calc(2 * var(--font-size-header));
          @media (max-aspect-ratio: 1/1) {
            display: block;
          }
        }

        .modal-settings-body-volume-bar {
          flex: 1;
          border-radius: calc(var(--size-button-height) / 2);
          border: 2px solid #A32B2C;
          background: white;
          width: calc(100% - 2 * var(--size-button-height));
          height: var(--size-button-height);
          @media (max-aspect-ratio: 1/1) {
            display: none;
          }

          .modal-settings-body-volume-bar-handle {
            width: var(--size-volume-bar-handle);
            height: var(--size-volume-bar-handle);
            background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting7.png");
            background-repeat: no-repeat;
          }
        }

        .modal-settings-body-volume-plus {
          width: calc(var(--size-button-height) / 5 * 3);
          height: calc(var(--size-button-height) / 5 * 3);
          background-image: url("../../assets-v4/20230706_main/mainpage_dialog/setting/mainpage_dialog_setting8.png");
        }
      }

      .modal-settings-body-buttons {
        display: flex;
        gap: calc(var(--ar-size-ratio) * 20000);
        flex-direction: row;
        @media (max-aspect-ratio: 1/1) {
          gap: 10px;
          flex-direction: column;
        }

        button {
          width: calc(var(--size-button-height) * 3);
          height: var(--size-button-height);
          border-radius: calc(var(--size-button-height) / 2);
          border: 2px solid #0069BA;
          background: white;
          font-size: var(--font-size-base);
          font-weight: bold;
        }
      }
    }
  }
}
